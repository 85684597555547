@import 'abstracts/variables';

@mixin widget_menu($justify-content: center) {
    height: 60px;
    width: 100%;
    background-color: $second-color;
    color: $white-color;
    display: flex;
    align-items: center;
    justify-content: $justify-content
}
@mixin title-h($align, $fz, $lh, $color) {
  text-align: $align;
  font-size: $fz;
  line-height: $lh;
  color: $color;
}
@mixin font_title($font-size,$font-weight, $line-height,$color){
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    color: $color;
}

@mixin d_flex($flex, $justify-content, $align-item){
    display: $flex;
    justify-content: $justify-content;
    align-items: $align-item;
}


@mixin  border($w, $color){
    border-width: $w;
    border-style: solid;
    border-color: $color;
}
@mixin link($color,$fs) {
    text-decoration: none;
    color: $color;
    font-size: $fs;
}
// media query for all screen

@mixin  screen ($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: map-get($breakpoints, $breakpoint )){
            @content;
        }
    }
    @else {
        @warn "Unfortunately, `#{$breakpoint}` can not found"
        + "Avaiable breakpoints are : #{map-keys($breakpoints)}";
    }
}
@mixin  min-screen ($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
      @media (min-width: map-get($breakpoints, $breakpoint )){
          @content;
      }
  }
  @else {
      @warn "Unfortunately, `#{$breakpoint}` can not found"
      + "Avaiable breakpoints are : #{map-keys($breakpoints)}";
  }
}
/** Media query Mobile**/
@mixin Mobiles ($min-iPhoneX, $max-iPhoneX, $pixel-ratio, $typescreen) {
    @media only screen and (min-device-width: $min-iPhoneX)
                and (max-device-width: $max-iPhoneX)
                and (#{$pixel-ratio})
                and (#{$typescreen}) {
        @content;
    }
}
