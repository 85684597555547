* , html, ul, li{
    box-sizing: border-box;
    margin: 0;
}
html, body {
    margin: 0;
    height: 100%;
    background-color: white;
    font-family: Roboto, sans-serif;
}
// modify background .mat-expansion-panel-header --- product details component
.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover{
    background: none !important;
}
.mat-expansion-indicator {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}


