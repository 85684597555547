/*** Avaiables ***/


$primary-color: #e573aa;
$second-color: #EE96BD;
$text-color-sakura: #ED95BD;
$points-sakura-bg: #FCE0E8;

$white-color: #fff;

$black-color : #000000;
$black-color-second: #000000DE;
$black-color-third:#100F0F;
$text-black-color: ($black-color, 0.87);


$primary-gray-color: #727171;
$second-gray-color: #f5f5f5;
$third-gray-color: #E6E6E6;
$four-gray-color: #707070;

$background-menu: #f38ab4;

$font-default: 16px;

$line-height-default: 23px;
$line-height-primary: 48px;

$fs-default: 1em;
$lh-default: 1.2em;

$sakura-color: #E573AA;
$sakura-96-color: #EE96BD;
$sakura-95-color: #ED95BD;
$sakura-fill-color: #FFF4F7;

$copy-icon: #00000061;

$height-default: 16px;
$margin-default: 20px;

$pd-default: 1rem;
$mg-default: 1rem;
// breakpoints media query

$breakpoints : ('responsive': 480px,
  'phone': 599px,
  'phone-landscape': 812px,
  'tablet-portrait': 600px,
  'taplet-landscape': 960px,
  'desktop': 1208px,
  'big-desktop': 1920px) !default;

/** Breakpoints for Mobile **/
$min-iPhone11: 414px;
$max-iPhone11: 896px;
// iPhone X
$min-iPhoneX: 375px;
$max-iPhoneX: 812px;
// iPhone 6/7/8 plus
$min-iPhone678-plus: 414px;
$max-iPhone678-plus: 736px;
// iPhone 678
$min-iPhone678: 375px;
$max-iPhone678: 667px;
// iPhone 5
$min-iPhone5: 320px;
$max-iPhone5: 568px;
// pixel ratio
$pixel-ratio-2: "-webkit-min-device-pixel-ratio: 2";
$pixel-ratio-3: "-webkit-min-device-pixel-ratio: 3";
// type screen
$landscape: "orientation: landscape";
$portrait: "orientation: portrait";
