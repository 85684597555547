@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
*, html, ul, li {
  box-sizing: border-box;
  margin: 0;
}

html, body {
  margin: 0;
  height: 100%;
  background-color: white;
  font-family: Roboto, sans-serif;
}

.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
  background: none !important;
}

.mat-expansion-indicator {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

/*** Avaiables ***/
/** Breakpoints for Mobile **/
/*** Avaiables ***/
/** Breakpoints for Mobile **/
/** Media query Mobile**/
@keyframes notifySnackBarFace {
  /*「fadeIn」と名前をそろえる*/
  0% {
    opacity: 0;
  }
  /*アニメーション開始時は完全に透過*/
  100% {
    opacity: 1;
  }
  /*アニメーション終了時は透過しない*/
}
.notify_snackbar {
  height: 60px;
}

.notify_snackbar.success {
  animation: notifySnackBarFace 100ms ease 0s 1 normal;
  padding: 1.3rem 0.5rem 1.3rem 1.5rem;
  color: white;
  background-color: rgba(64, 128, 255, 0.9);
  width: 480px !important;
}

.notify_snackbar.success button {
  text-align: center;
  color: white;
}

.notify_snackbar.info {
  animation: notifySnackBarFace 100ms ease 0s 1 normal;
  padding: 1rem 1rem 1rem 1rem;
  color: white;
  background-color: rgba(32, 32, 32, 0.9);
}

.notify_snackbar.info button {
  text-align: center;
  color: white;
}

.notify_snackbar.warning {
  animation: notifySnackBarFace 100ms ease 0s 1 normal;
  padding: 0.5rem 1rem 0.5rem 1rem;
  color: black;
  background-color: rgba(255, 128, 0, 0.9);
  width: 480px !important;
  border-radius: none !important;
}

.notify_snackbar.warning button {
  text-align: center;
  color: black;
}

.notify_snackbar.error {
  animation: notifySnackBarFace 100ms ease 0s 1 normal;
  padding: 1.3rem 0.5rem 1.3rem 1.5rem;
  color: black;
  background-color: rgba(255, 128, 0, 0.9);
  width: 480px !important;
  border-radius: none !important;
}

.notify_snackbar.error button {
  text-align: center;
  color: black;
}

.notify_snackbar.critical {
  animation: notifySnackBarFace 100ms ease 0s 1 normal;
  padding: 1rem 1rem 1rem 1rem;
  color: black;
  background-color: red;
}

.notify_snackbar.critical button {
  text-align: center;
  color: black;
}

.mat-snack-bar-handset {
  justify-content: center;
  height: auto !important;
}

div.widget mat-form-field {
  width: 100%;
}

.mat-cell,
.mat-header-cell,
.mat-footer-cell {
  font-size: 0.75rem;
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  padding: 0.3rem !important;
}

tr.mat-header-row {
  height: 2rem !important;
}

tr.mat-row {
  height: 1.2rem !important;
}

div.mat-tab-label-content {
  height: 30px;
  min-width: 100px;
  font-size: 0.75rem;
}

div.mat-tab-label {
  height: 30px;
}